





























import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class DestinyButton extends Vue {
  @Prop({ default: "" })
  private readonly theme!: "yellow" | "orange" | "green";

  @Prop({ default: "" })
  private readonly tag!: string;

  @Prop({ default: false })
  private readonly warning!: boolean | any;

  @Prop({ default: false })
  private readonly round!: boolean;

  @Prop({ default: false })
  private readonly disabled!: boolean;
  @Prop({ default: false })
  private readonly popState!: boolean;
  @Prop({ default: false })
  private readonly ensure!: Object;

  get is_warning(): boolean {
    return this.warning !== false;
  }

  @Emit()
  handleClick(e: any): any {
    return e;
  }
}
